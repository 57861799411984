import { useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ICONS } from 'src/assets/library';
import { companySchema } from 'src/config/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSelectField from '../formFields/selectField';
import { CREATE_COMPANY, UPDATE_COMPANY } from 'src/apiService/apiDeclaration';
import CustomTextField from 'src/components/formFields/textField';
import LoaderButton from 'src/components/loaderButton/loaderButton';
import { formatDate, getChangedFields, modelArray, showErrorTost, showSuccessTost } from 'src/constants';

const CompanyForm = ({ data, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const defaultValues = data?._id
    ? {
        email: data.email,
        name: data.name,
        number: data.number,
        package: { expireOn: formatDate(data.package.expireOn) },
        packageFeatures: {
          model: data.packageFeatures.model,
          words: data.packageFeatures.words,
          credits: data.packageFeatures.credits,
        },
        hrDomain: data?.hrDomain,
        hrId: data?.hrId,
      }
    : {};
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(companySchema),
    defaultValues,
  });

  const onSubmit = async (body) => {
    setLoading(true);
    try {
      if (data?._id) {
        await UPDATE_COMPANY(data._id, getChangedFields(dirtyFields, body));
      } else {
        await CREATE_COMPANY(body);
      }
      showSuccessTost('Company updated successfully.');
      onSuccess();
    } catch (error) {
      showErrorTost('Error in updating company.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            placeholder={'Jhon smith'}
            icon={ICONS.user}
            controller={{
              name: 'name',
              control: control,
              errors: errors.name,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            placeholder={'name@domain.com'}
            type="email"
            icon={ICONS.email}
            controller={{
              name: 'email',
              control: control,
              errors: errors.email,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            placeholder={'+243 82 xxx xx xx'}
            label={'Contact Number'}
            type="number"
            icon={ICONS.phone}
            controller={{
              name: 'number',
              control: control,
              errors: errors.number,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            label={'Words'}
            type="number"
            icon={ICONS.words}
            controller={{
              name: 'packageFeatures.words',
              control: control,
              errors: errors?.packageFeatures?.words,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            label={'Credits'}
            type="number"
            icon={ICONS.words}
            controller={{
              name: 'packageFeatures.credits',
              control: control,
              errors: errors?.packageFeatures?.credits,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelectField
            label={'Model'}
            optionsArray={modelArray}
            controller={{
              name: 'packageFeatures.model',
              control: control,
              errors: errors?.packageFeatures?.model,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'Expiration Date'}
            type="date"
            icon={ICONS.date}
            controller={{
              name: 'package.expireOn',
              control: control,
              errors: errors?.package?.expireOn,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'HR Domain'}
            icon={ICONS.domain}
            controller={{
              name: 'hrDomain',
              control: control,
              errors: errors.hrDomain,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={'HR Id'}
            icon={ICONS.user}
            controller={{
              name: 'hrId',
              control: control,
              errors: errors.hrId,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <LoaderButton
            disabled={!isValid || !isDirty}
            loading={loading}
            buttonText={data?._id ? 'Update' : 'Add'}
            type="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyForm;
