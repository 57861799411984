import { apiService, apiStreamService } from './apiWrapper';

/////////Stream Request//////////
export const DOWNLOAD_FILE = (fileId) => {
  return apiStreamService(`jacques/download/${fileId}`, 'GET');
};
/////////POST REQUESTS///////////

export const SIGNIN = (body) => {
  return apiService('auth/login', 'POST', { ...body, isAdmin: true });
};
export const ADD_USER = (body) => {
  return apiService('user', 'POST', body);
};
export const UPLOAD_FILE = (body, id) => {
  return apiService(`assistant/${id}/file`, 'POST', body);
};
export const CREATE_FAQ = (body) => {
  return apiService('faq', 'POST', body);
};
export const CREATE_BLOG = (body) => {
  return apiService('blog', 'POST', body);
};
export const CREATE_PACKAGE = (body) => {
  return apiService('package', 'POST', body);
};
export const CREATE_PROMPT = (body) => {
  return apiService('prompt', 'POST', body);
};
export const CREATE_COMPANY = (body) => {
  return apiService('company', 'POST', body);
};
export const CREATE_ASSISTANT = (body) => {
  return apiService('assistant', 'POST', body);
};
export const ASSIGN_FREE_PACKAGE = (body) => {
  return apiService('user/assign-package', 'POST', body);
};

/////////GET REQUESTS///////////
export const AUTH_ME = () => {
  return apiService('auth/auth-me', 'GET');
};
export const GET_USER = (params) => {
  return apiService(`user?${params}`, 'GET');
};
export const GET_COMPANY_LIST = (params) => {
  return apiService(`company?${params}`, 'GET');
};

export const ASSISTANT_TRAINING = (id) => {
  return apiService(`assistant/${id}/train`, 'GET');
};

export const GET_USER_STATS = () => {
  return apiService(`user/stats`, 'GET');
};

export const GET_PACKAGE_LIST = (params) => {
  return apiService(`package?${params}`, 'GET');
};
export const GET_FEEDBACK = (params) => {
  return apiService(`feedback?${params}`, 'GET');
};
export const GET_PRIVACY = () => {
  return apiService(`privacy`, 'GET');
};
export const GET_CHAT = () => {
  return apiService(`chatPage`, 'GET');
};
export const GET_FOOTER = () => {
  return apiService(`footer`, 'GET');
};
export const GET_FAQ_LIST = (params) => {
  return apiService(`faq?${params}`, 'GET');
};
export const GET_SETTINGS = () => {
  return apiService(`setting`, 'GET');
};
export const GET_ASSISTANT = (id) => {
  return apiService(`assistant/${id}`, 'GET');
};
export const GET_PROMPT_LIST = (params) => {
  return apiService(`prompt?${params}`, 'GET');
};

export const GET_BLOG_LIST = (params) => {
  return apiService(`blog?${params}`, 'GET');
};
export const GET_BLOG_PAGE = () => {
  return apiService(`blog-page`, 'GET');
};
export const GET_CUSTOM_PACKAGE = () => {
  return apiService(`custom-package`, 'GET');
};
export const GET_ABOUT_PAGE = () => {
  return apiService(`about`, 'GET');
};
export const GET_HR_PAGE = () => {
  return apiService(`hr`, 'GET');
};
export const GET_HOME_PAGE = () => {
  return apiService(`home`, 'GET');
};

/////////PUT REQUESTS///////////

export const UPDATE_CUSTOM_PACKAGE = (id, data) => {
  return apiService(`custom-package/${id}`, 'PUT', data);
};

export const BULK_ACTION_EMPLOYEE = (id, data) => {
  return apiService(`company/${id}/bulk-action`, 'PUT', data);
};
export const UPDATE_ASSISTANT = (id, data) => {
  return apiService(`assistant/${id}`, 'PUT', data);
};
export const UPDATE_USER = (id, data) => {
  return apiService(`user/${id}`, 'PUT', data);
};
export const UPDATE_COMPANY = (id, data) => {
  return apiService(`company/${id}`, 'PUT', data);
};
export const UPDATE_PROMPT = (id, data) => {
  return apiService(`prompt/${id}`, 'PUT', data);
};
export const UPDATE_FEEDBACK = (id, data) => {
  return apiService(`feedback/${id}`, 'PUT', data);
};
export const UPDATE_PRIVACY = (id, data) => {
  return apiService(`privacy/${id}`, 'PUT', data);
};
export const UPDATE_CHAT = (id, data) => {
  return apiService(`chatPage/${id}`, 'PUT', data);
};
export const UPDATE_FOOTER = (id, data) => {
  return apiService(`footer/${id}`, 'PUT', data);
};
export const UPDATE_FAQ = (id, data) => {
  return apiService(`faq/${id}`, 'PUT', data);
};
export const UPDATE_SETTINGS = (id, data) => {
  return apiService(`setting/${id}`, 'PUT', data);
};
export const UPDATE_BLOG = (id, data) => {
  return apiService(`blog/${id}`, 'PUT', data);
};
export const UPDATE_BLOG_PAGE = (id, data) => {
  return apiService(`blog-page/${id}`, 'PUT', data);
};
export const UPDATE_ABOUT_PAGE = (id, data) => {
  return apiService(`about/${id}`, 'PUT', data);
};
export const UPDATE_HR_PAGE = (id, data) => {
  return apiService(`hr/${id}`, 'PUT', data);
};
export const UPDATE_HOME_PAGE = (id, data) => {
  return apiService(`home/${id}`, 'PUT', data);
};
export const UPDATE_PACKAGE = (id, data) => {
  return apiService(`package/${id}`, 'PUT', data);
};

/////////DELETE REQUESTS///////////

export const DELETE_USER = (id) => {
  return apiService(`user/${id}`, 'DELETE');
};
export const DELETE_PROMPT = (id) => {
  return apiService(`prompt/${id}`, 'DELETE');
};
export const DELETE_COMPANY = (id) => {
  return apiService(`company/${id}`, 'DELETE');
};

export const DELETE_FEEDBACK = (id) => {
  return apiService(`feedback/${id}`, 'DELETE');
};

export const DELETE_FAQ = (id) => {
  return apiService(`faq/${id}`, 'DELETE');
};

export const DELETE_BLOG = (id) => {
  return apiService(`blog/${id}`, 'DELETE');
};

export const DELETE_PACKAGE = (id) => {
  return apiService(`package/${id}`, 'DELETE');
};
export const DELETE_ASSISTANT = (id) => {
  return apiService(`assistant/${id}`, 'DELETE');
};
export const DELETE_FILE = (id) => {
  return apiService(`assistant/${id}/file`, 'DELETE');
};
