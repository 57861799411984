import { ICONS } from 'src/assets/library';

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: ICONS.dashboard,
  },
  {
    title: 'user',
    path: '/user',
    icon: ICONS.user,
  },
  {
    title: 'company',
    path: '/company',
    icon: ICONS.company,
  },
  {
    title: 'feedback',
    path: '/feedback',
    icon: ICONS.feedback,
  },
  {
    title: 'home',
    path: '/home',
    icon: ICONS.home,
  },

  {
    title: 'chat',
    path: '/chat',
    icon: ICONS.chat,
  },
  {
    title: 'prompt',
    path: '/prompt',
    icon: ICONS.prompmt,
  },
  {
    title: 'settings',
    path: '/settings',
    icon: ICONS.settings,
  },
  {
    title: 'package',
    path: '/package',
    icon: ICONS.package,
  },
  {
    title: 'custom package',
    path: '/custom-package',
    icon: ICONS.package,
  },
  {
    title: 'blog',
    path: '/blog',
    icon: ICONS.blog,
  },
  {
    title: 'footer',
    path: '/footer',
    icon: ICONS.footer,
  },
  {
    title: 'faq',
    path: '/faq',
    icon: ICONS.faq,
  },
  {
    title: 'about',
    path: '/about',
    icon: ICONS.about,
  },
  {
    title: 'hr',
    path: '/hr',
    icon: ICONS.user,
  },
  {
    title: 'privacy',
    path: '/privacy',
    icon: ICONS.privacy,
  },
];

export default navConfig;
